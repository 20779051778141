import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Flex, Heading, VStack, chakra } from '@chakra-ui/react'
import { _dvd, _usb, _videobook } from './data'

const FAQ = ({ product }) => {
	const [toggled, setToggled] = useState(false)

	var Data = <></>
	switch (product) {
		case 'dvd':
			Data = _dvd
			break
		case 'usb':
			Data = _usb
			break
		case 'videobook':
			Data = _videobook
			break
		default:
			Data = <></>
	}

	return (
		<VStack py="0.5rem" textAlign="left" spacing="1rem" w="full" borderTop="1px" borderColor="gray.100">
			<Flex
				pos="relative"
				justifyContent="space-between"
				w="full"
				minH="44px"
				alignItems="center"
				onClick={() => setToggled(!toggled)}>
				<Heading as="h3" size="sm" fontWeight="bold" color="dark">
					Frequently Asked Questions
				</Heading>
				<chakra.span
					sx={{
						display: 'block',
						pos: 'absolute',
						right: 0,
						top: '6px',
						content: '""',
						w: '32px',
						h: '32px',
						backgroundSize: '32px',
						backgroundPosition: 'center',
						backgroundImage: '/assets/images/keepsakes/icon-faq-arrow-down.svg',
						transition: 'all 0.5s ease',
						transform: toggled ? 'rotate(180deg)' : 'none',
					}}
				/>
			</Flex>
			{toggled && <Data />}
		</VStack>
	)
}

FAQ.defaultProps = {
	product: '',
}

FAQ.propTypes = {
	product: PropTypes.string.isRequired,
}

export default FAQ
