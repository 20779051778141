import { useState, useEffect } from 'react'

/** Helpers */
import { getUserLocation } from '@vidday/utils'

const useUserLocation = () => {
	const [userLocation, setUserLocation] = useState(null) // user location, default to null

	/**
	 * Retrieve User's location
	 */
	useEffect(() => {
		getUserLocation()
			.then((l) => {
				setUserLocation(l)
			})
			.catch((err) => {
				//console.error('Failed to get user location : ', err)
				setUserLocation(null)
			})
	}, [])

	return userLocation
}

export default useUserLocation
