export default function getGAClientId() {
	if (typeof window !== 'undefined') {
		const cookie = window.document.cookie

		// see if "_ga" cookie exists
		if (cookie.indexOf('_ga=') > -1) {
			const _ga = cookie.split('_ga=')[1].split(';')[0].trim()
			const _gaParts = _ga.split('.') // has shape "GA1.1.XXXXXXX.YYYYYYYY"
			// verify cookie starts with the proper GA prefix
			if (_gaParts[0] === 'GA1') {
				// analytics client id is the "XXXXXX.YYYYYY" part
				return `${_gaParts[2]}.${_gaParts[3]}`
			}
		}
	}
	return null
}
