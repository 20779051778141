import React from 'react'
import { VStack, Heading, Text, Link } from '@chakra-ui/react'

export const _dvd = () => {
	return (
		<VStack spacing="1rem" alignItems="flex-start">
			<Heading as="h4" size="sm" color="dark">
				When will my order ship?
			</Heading>
			<Text color="dark">
				We ship everything from our California offices within 4 – 13 business days with USPS.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				Do you ship internationally?
			</Heading>
			<Text color="dark">
				Yes, international orders are shipped within 10 – 21 business days with DHL Global. Please note that
				international orders may be subject to import/value-added taxes beyond our control.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				What’s the difference between NTSC and PAL format?
			</Heading>
			<Text color="dark">
				These formats determine if a DVD is compatible with DVD machines manufactured in your country.
			</Text>
			<Text color="dark">
				<strong>NTSC format</strong> works in the United States, Canada, Mexico, Japan, South Korea, Taiwan, and
				South America (except Argentina, Brazil, Paraguay, and Uruguay).
			</Text>
			<Text color="dark">
				<strong>PAL format</strong> works in all other countries.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				How do I contact someone?
			</Heading>
			<Text color="dark">
				Customer service chat hours every day from 7 am – 12 am CST. You can also reach us by email at{' '}
				<Link textDecoration="underline" href="mailto:support@vidday.com">
					support@vidday.com
				</Link>
				. Or checkout more answers in our{' '}
				<Link textDecoration="underline" href="https://help.vidday.com/11-dvd-usb/" target="_blank">
					help center
				</Link>
				.
			</Text>
		</VStack>
	)
}

export const _usb = () => {
	return (
		<VStack spacing="1rem" alignItems="flex-start">
			<Heading as="h4" size="sm" color="dark">
				When will my order ship?
			</Heading>
			<Text color="dark">
				We ship everything from our California offices within 4 – 13 business days with USPS.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				Do you ship internationally?
			</Heading>
			<Text color="dark">
				Yes, international orders are shipped within 10 – 21 business days with DHL Global. Please note that
				international orders may be subject to import/value-added taxes beyond our control.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				How do I contact someone?
			</Heading>
			<Text color="dark">
				Customer service chat hours every day from 7 am – 12 am CST. You can also reach us by email at{' '}
				<Link textDecoration="underline" href="mailto:support@vidday.com">
					support@vidday.com
				</Link>
				. Or checkout more answers in our{' '}
				<Link textDecoration="underline" href="https://help.vidday.com/11-dvd-usb/" target="_blank">
					help center
				</Link>
				.
			</Text>
		</VStack>
	)
}

export const _videobook = () => {
	return (
		<VStack spacing="1rem" alignItems="flex-start">
			<Heading as="h4" size="sm" color="dark">
				What’s a video book?
			</Heading>
			<Text color="dark">
				A physical book that plays your video when the cover opens. VidDay loads your book with your video and
				ships it directly to you or your loved one. VidDay’s premium video book includes a speaker that plays
				the audio with crystal clear quality. The player uses a 5” HD LCD screen with a resolution of 854x480.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				How long will it take to process my order?
			</Heading>
			<Text color="dark">
				We typically process and ship orders within 24 hours during the work week. We generally use USPS
				shipping for your finished video book.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				How much does shipping cost?
			</Heading>
			<Text color="dark">
				We offer free shipping in the US. There is a shipping cost of $20 USD for international destinations.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				Does my recipient need to have a computer or phone? Do they need Internet or WiFi?
			</Heading>
			<Text color="dark">
				No. VidDay’s video books start playing your video when opened. The books do not need to be initially
				charged or interfaced with technology in any way. If you can look at a photo, you can watch a VidDay
				video book.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				Do VidDay video books support playing my video’s sound?
			</Heading>
			<Text color="dark">
				Yes. Our VidDay video books include a tiny speaker to play any sound recorded with your video. We
				automatically optimize your video’s audio so it sounds great.
			</Text>

			<Heading as="h4" size="sm" color="dark">
				What’s your return policy?
			</Heading>
			<Text color="dark">
				Our books are a custom product made just for your recipient, limiting our ability to refund delivered
				orders. If you are not satisfied for any reason, please get in touch with us, and we will make it right.
				We will also happily replace any book that has technical issues.
			</Text>
			<Text color="dark">
				Please contact us at support@vidday.com to receive a return authorization. Customer service chat hours
				every day from 7 am – 12 am CST. You can also reach us by email at{' '}
				<Link textDecoration="underline" href="mailto:support@vidday.com">
					support@vidday.com
				</Link>
				.
			</Text>
		</VStack>
	)
}
