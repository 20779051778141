import React, { useState } from 'react'
import {
	Box,
	Modal,
	Icon,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'
import { CheckCircleRounded, RadioButtonUncheckedRounded } from '../../atoms/Icon'

const boxStyles = {
	borderWidth: '1px',
	borderRadius: 'md',
	borderColor: '#6C7A88',
	p: '1rem',
	_hover: {
		'cursor': 'pointer',
		'borderColor': 'var(--vidday-colors-link)',
		'bg': 'rgba(0,102,204, 0.1)',
		'& *': {
			cursor: 'pointer',
		},
	},
}

const SelectableBox = ({ onSelect, isSelected, title, children }) => {
	const selectedStyles = {
		borderColor: 'var(--vidday-colors-link)',
		bg: 'rgba(0,102,204, 0.1)',
	}
	const styles = isSelected ? { ...boxStyles, ...selectedStyles } : boxStyles

	return (
		<Box onClick={onSelect} sx={styles}>
			<HStack spacing="1rem">
				{isSelected ? <CheckCircleRounded color="link" /> : <RadioButtonUncheckedRounded color="gray.900" />}

				<Box textAlign="left" w="full">
					<Text color={isSelected ? 'var(--vidday-colors-link)' : 'dark'} fontWeight="bold">
						{title}
					</Text>
					<Text fontSize="0.875rem" color="dark">
						{children}
					</Text>
				</Box>
			</HStack>
		</Box>
	)
}

const ModalDVDFormat = ({ onClose, isOpen, onSetFormat }) => {
	const img = useImageGenerator({
		path: '/assets/images/keepsakes/vidday-dvd-format-globe',
		ext: 'png',
	})
	const [selection, setSelection] = useState('')

	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="lg">
			<ModalOverlay />
			<ModalContent px={['1rem', '2rem']}>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image src={img.src} srcSet={img.srcset} alt="DVD Format" width="100px" height="100px" />

						<Heading color="dark" as="h3" size="lg" variant="hero">
							Select DVD Format
						</Heading>

						<Text color="dark">
							<strong>Important:</strong> Choose your compatible format for DVD players in your region
						</Text>
						<SelectableBox
							isSelected={selection == 'NTSC'}
							onSelect={() => setSelection(selection != 'NTSC' ? 'NTSC' : '')}
							title="NSTC Format">
							United States 🇺🇸 / Canada 🇨🇦 / Mexico 🇲🇽 /Japan 🇯🇵 / South Korea 🇰🇷 / Taiwan 🇹🇼 / South
							America{' '}
							<Text fontSize="0.75rem" display="inline">
								(except: Argentina, Brazil, Paraguay, and Uruguay)
							</Text>
						</SelectableBox>

						<SelectableBox
							isSelected={selection == 'PAL'}
							onSelect={() => setSelection(selection != 'PAL' ? 'PAL' : '')}
							title="PAL Format">
							United Kingdom 🇬🇧 / France 🇫🇷 / Australia 🇦🇺/ all other countries
						</SelectableBox>
					</VStack>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							Back
						</Button>
						<Button
							onClick={() => {
								if (selection) {
									onSetFormat(selection)
								}
								onClose()
							}}>
							Continue
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalDVDFormat
