import React, { useEffect, useRef, useState } from 'react'
import { AspectRatio, chakra, Flex, Image, Link, Stack } from '@chakra-ui/react'
import PropTypes from 'prop-types'
import useWindowSize from '../../../../../hooks/useWindowSize'
import { useSelector, shallowEqual } from 'react-redux'

const Gallery = ({ product }) => {
	const publishedMedia = useSelector((s) => s.event.publishedMedia, shallowEqual)
	const [images, setImages] = useState([])

	useEffect(() => {
		let imgs = []

		if (!publishedMedia) {
			if (product == 'dvd') {
				imgs.push({ path: '/assets/images/keepsakes/vidday-dvd-1' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-dvd-2' })
			} else if (product == 'usb') {
				imgs.push({ path: '/assets/images/keepsakes/vidday-usb-1' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-usb-2' })
			} else if (product == 'videobook') {
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-1', ext: '.gif' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-2', ext: '.jpg' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-3', ext: '.jpg' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-4', ext: '.jpg' })
			}
		} else if (publishedMedia && !Array.isArray(publishedMedia)) {
			const { dvdFullImageUrl, dvdDiskImageUrl, usbFullImageUrl } = publishedMedia

			if (product == 'dvd') {
				if (dvdFullImageUrl) {
					imgs.push({ path: dvdFullImageUrl, signed: true })
				}
				if (dvdDiskImageUrl) {
					imgs.push({ path: dvdDiskImageUrl, signed: true })
				}
			} else if (product == 'usb') {
				if (usbFullImageUrl) {
					imgs.push({ path: usbFullImageUrl, signed: true })
					imgs.push({ path: '/assets/images/keepsakes/vidday-usb-2' })
				}
			} else if (product == 'videobook') {
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-1', ext: '.gif' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-2', ext: '.jpg' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-3', ext: '.jpg' })
				imgs.push({ path: '/assets/images/keepsakes/vidday-video-book-4', ext: '.jpg' })
			}
		}
		setImages(imgs)
	}, [publishedMedia])

	const [currentImage, setCurrentImage] = useState(0)

	if (images.length > 0) {
		return (
			<>
				<Flex direction={['row', 'column']}>
					<AspectRatio
						minW={[
							'calc(100% - (65px + 0.5rem))',
							'calc(100% - (65px + 0.5rem))',
							'calc(100% - (65px + 0.5rem))',
							'512px',
						]}
						ratio={128 / 93}>
						<Image
							src={`${images[currentImage].path}${
								!images[currentImage].signed
									? images[currentImage].ext
										? images[currentImage].ext
										: '.png'
									: ''
							}`}
							borderRadius="8px"
						/>
					</AspectRatio>

					<Stack
						direction={['column', 'row']}
						spacing={['0.5rem', '1rem']}
						px={['0.5rem', '0']}
						py={['0', '1rem']}>
						{images.map((img, i) => {
							return (
								<Link
									display="flex"
									lineH="0"
									opacity={currentImage == i ? '0.5' : '1'}
									onClick={() => setCurrentImage(i)}>
									<Image
										src={`${img.path}${!img.signed ? (img.ext ? img.ext : '.png') : ''}`}
										minW="65px"
										width="65px"
										height="auto"
										borderRadius="8px"
									/>
								</Link>
							)
						})}
					</Stack>
				</Flex>
			</>
		)
	}

	return <></>
}

Gallery.defaultProps = {
	product: '',
}

Gallery.propTypes = {
	product: PropTypes.string.isRequired,
}

export default Gallery
