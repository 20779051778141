import React from 'react'
import {
	Modal,
	Button,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	SimpleGrid,
	Flex,
	GridItem,
	ModalHeader,
} from '@chakra-ui/react'
import useImageGenerator from '../../../hooks/useImageGenerator'

const covers = [
	{ id: 'blank' },
	{ id: 'animal' },
	{ id: 'baby' },
	{ id: 'birthday' },
	{ id: 'flowers' },
	{ id: 'good-times' },
	{ id: 'hearts' },
	{ id: 'holidays-retro' },
	{ id: 'holidays-snow' },
	{ id: 'love' },
	{ id: 'made-with-love' },
	{ id: 'memories' },
	{ id: 'thank-you' },
	{ id: 'wedding' },
	{ id: 'xmas-deer' },
	{ id: 'xmas-red' },
	{ id: 'xmas-snow' },
]

const GridItemButtons = ({ onSetCover }) => {
	return covers.map((el, i) => {
		const img = useImageGenerator({
			path: `/assets/images/keepsakes/video-book-covers/${el.id}`,
			ext: 'jpg',
		})
		return (
			<GridItem minW="152px" key={i} colSpan={1} onClick={() => onSetCover(el.id)}>
				<Flex justifyContent="center">
					<Image
						w="152px"
						h="152px"
						src={img.src}
						srcSet={img.srcset}
						border="1px solid"
						borderColor="gray.200"
						borderRadius="6px"
					/>
				</Flex>
			</GridItem>
		)
	})
}

const ModalVideoBookCoverSelector = ({ onClose, isOpen, onSetCover }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="3xl">
			<ModalOverlay />
			<ModalContent px={['1rem']} maxH="80vh">
				<ModalCloseButton />
				<ModalHeader>
					<Flex justifyContent={'center'} mb="1rem">
						<Text fontSize="1.5rem" color="black">
							Choose a Cover
						</Text>
					</Flex>
				</ModalHeader>
				<ModalBody maxH="80vh" overflowY="auto" p="1rem">
					<SimpleGrid columns={['2', '3', '4']} gap={4}>
						<GridItemButtons onSetCover={onSetCover} />
					</SimpleGrid>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button variant="ghost" onClick={onClose}>
							cancel
						</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}

export default ModalVideoBookCoverSelector
