import * as Yup from 'yup'

export default Yup.object({
	isDVD: Yup.boolean(),
	format: Yup.mixed().when('isDVD', {
		is: (val) => val === true,
		then: Yup.mixed().oneOf(['NTSC', 'PAL']).required(),
		otherwise: Yup.mixed().notRequired().nullable(true).default(null),
	}),
	quantity: Yup.number().required(),
	isVideoBook: Yup.boolean(),
	coverArt: Yup.string().when('isVideoBook', {
		is: (val) => val === true,
		then: Yup.string().required('Please select cover art'),
		otherwise: Yup.string().notRequired().nullable(true).default(null),
	}),
})
