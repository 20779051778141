import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import schemaKeepsake from './schema'
import { Button, chakra, Flex, Text, useDisclosure, LightMode, Image, Box } from '@chakra-ui/react'
import ModalDVDFormat from '../../../../organisms/ModalDVDFormat'
import ModalVideoBookCoverSelector from '../../../../organisms/ModalVideoBookCoverSelector'
import { RemoveRounded, AddRounded, RotateRounded } from '../../../../atoms/Icon'
import ControlledCheckbox from '../../../../molecules/controls/Checkbox'

const stepperBtnStyles = {
	borderWidth: '1px',
	borderColor: '#B5BCC3',
	h: '32px',
	alignItems: 'center',
	width: '32px',
	textAlign: 'center',
	justifyContent: 'center',
	backgroundColor: 'white',
	cursor: 'pointer',
	color: 'gray.700',
	_disabled: {
		color: '#DADDE1',
		backgroundColor: 'white',
		cursor: 'default',
	},
}

const ProductForm = ({ isDVD, isVideoBook, onSubmit }) => {
	/** Modal about DVD Format */
	const [isOpen, setIsOpen] = useState(false)

	const dvdFormat = useDisclosure()
	const coverSelector = useDisclosure()

	const formRef = useRef()

	// const [isSubmitting, setIsSubmitting] = useState(false)
	/** Define form */
	const { handleSubmit, errors, control, watch, setValue, register, clearErrors, formState } = useForm({
		mode: 'onChange',
		defaultValues: { isDVD: isDVD, isVideoBook: isVideoBook, format: null, quantity: 1, addonGiftBox: false },
		resolver: yupResolver(schemaKeepsake),
	})

	const values = watch()

	const addQuantity = () => {
		setValue('quantity', values.quantity + 1)
	}

	const reduceQuantity = () => {
		values.quantity >= 2 && setValue('quantity', values.quantity - 1)
	}

	useEffect(() => {
		register({ name: 'coverArt' }, { required: isVideoBook ? true : false })
		register({ name: 'addonGiftBox' }, { required: false })
		register({ name: 'isVideoBook' }, { required: false })
		register({ name: 'isDVD' }, { required: false })
		register({ name: 'format' }, { required: isDVD ? true : false })
		register({ name: 'quantity' }, { required: true })
	}, [])

	/** For DVD Only */
	const submitFormThroughRef = () => {
		// setIsSubmitting(true)

		formRef.current?.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true }))
		console.log('form should have been submitted')
	}
	/** For DVD Only */
	const handleSetFormat = (format) => {
		setValue('format', format)
		/** Then let's submit the form */
		submitFormThroughRef()
	}

	/** For Video Book Only  */
	const handleSetCover = (coverId) => {
		setValue('coverArt', coverId)
		coverSelector.onClose()
		clearErrors('coverArt')
	}

	const buttonProps = isDVD ? { type: 'button', onClick: dvdFormat.onOpen } : { type: 'submit' }

	return (
		<chakra.form w="full" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
			{isDVD && (
				<LightMode>
					<ModalDVDFormat
						isOpen={dvdFormat.isOpen}
						onClose={dvdFormat.onClose}
						onSetFormat={handleSetFormat}
					/>
				</LightMode>
			)}

			{isVideoBook && (
				<>
					<ModalVideoBookCoverSelector
						isOpen={coverSelector.isOpen}
						onClose={coverSelector.onClose}
						onSetCover={handleSetCover}
					/>
					<Box mb="1rem">
						<Flex justify="space-between" alignItems="center">
							<Text>Choose cover art</Text>
							<Button
								variant="outline"
								h="2rem"
								justifyContent="space-between"
								onClick={() => coverSelector.onOpen()}>
								{values.coverArt ? 'Change' : 'Select Cover'}
								{values.coverArt && <RotateRounded ml=".5rem" mr="-0.75rem" />}
							</Button>
						</Flex>
						{errors['coverArt'] && (
							<Text m="0" p="0" color="pink.500" textAlign={'right'}>
								{errors['coverArt'].message}
							</Text>
						)}
					</Box>
					{values.coverArt && (
						<Image
							onClick={() => coverSelector.onOpen()}
							src={`/assets/images/keepsakes/video-book-covers/${values.coverArt}@2x.jpg`}
							mb="1.6rem"
							border="1px solid"
							borderColor="gray.200"
							borderRadius="6px"
						/>
					)}
					<chakra.hr mb="1rem" />
					<Flex justify="space-between" alignItems="center" mb="1rem">
						<ControlledCheckbox
							control={control}
							errors={errors}
							id={`addonGiftBox`}
							name={`addonGiftBox`}
							description={
								<chakra.span>
									Add a gift box with bow <chakra.strong color="gray.900">$12.00</chakra.strong>
								</chakra.span>
							}
							direction="row"
							justifyContent="start"
						/>
					</Flex>
					<chakra.hr mb="1rem" />
				</>
			)}

			<Flex justify="space-between" alignItems="center" mb="2rem">
				<Text fontSize="0.875rem" color="dark">
					Quantity
				</Text>
				<Flex h="32px">
					<Flex
						onClick={reduceQuantity}
						disabled={values.quantity <= 1}
						sx={stepperBtnStyles}
						borderRadius="8px 0 0 8px">
						<RemoveRounded w="16px" />
					</Flex>

					<Flex
						// disabled={values.quantity == 0 ? true : false}
						sx={{
							...stepperBtnStyles,
							color: 'gray.900',
							borderRight: 'none',
							borderLeft: 'none',
							_hover: {
								cursor: 'default',
							},
						}}>
						{values.quantity}
					</Flex>
					<Flex onClick={addQuantity} sx={stepperBtnStyles} borderRadius="0 8px 8px 0">
						<AddRounded w="16px" />
					</Flex>
				</Flex>
			</Flex>

			<Flex direction="column" mb="2rem">
				<LightMode>
					<Button
						variant="solid"
						size="lg"
						w="full"
						{...buttonProps}
						disabled={Object.keys(errors).length > 0 || values.quantity == 0}
						isLoading={formState.isSubmitting}>
						<span>{isDVD ? 'Continue' : 'Buy Now'}</span>
					</Button>
					{errors['coverArt'] && (
						<Text m=".5rem" p="0" color="pink.500" textAlign={'center'}>
							{errors['coverArt'].message}
						</Text>
					)}
				</LightMode>
				<Text mt="1rem" color="black" fontSize="xs">
					Planning a large order?&nbsp;
					<chakra.a
						_hover={{ textDecoration: 'underline' }}
						color="var(--vidday-colors-link)"
						href={'mailto:orders@vidday.com?subject=Bulk%20Keepsake%20Order'}
						title={'enterprise'}
						target="_blank"
						rel="noreferrer">
						Contact us
					</chakra.a>
					&nbsp;for bulk order discounts.
				</Text>
			</Flex>

			{/* css={`
                    margin-left: 0.25rem;
                    width: 16px !important;
                    height: 16px !important;
                    color: var(--global-grayscale-2);
                `} */}

			{/* /> */}
		</chakra.form>
	)
}

ProductForm.defaultProps = {
	isDVD: false,
}

ProductForm.propTypes = {
	onSubmit: PropTypes.func.isRequired,
	isDVD: PropTypes.bool.isRequired,
}
export default ProductForm
